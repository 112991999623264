import React from "react";
import "./Footer.css";
import { SlLocationPin, SlPhone } from "react-icons/sl";
import logo from "../../Assets/svg/logo.svg";
import { IoLogoGoogleplus } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="mainfooter">
      <div className="footerWrapper">
        <div className="footerDes">
          <span>
            <SlLocationPin className="footicon" />
          </span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <div className="footT">Location</div>
            <div className="footdes">
              PATEL NAGAR, BESA SQUARE, NAGPUR-440037{" "}
            </div>
            <ul className="socialicon">
              <li>
                <NavLink to="https://www.facebook.com/profile.php?id=61556256014733">
                  <FaFacebookF
                    style={{ color: "black", height: "20px", width: "20px" }}
                  />
                </NavLink>
              </li>

              <li>
                <NavLink to="https://www.google.com/search?q=patel+celebration+besa&sca_esv=bb2713ba757904f7&sca_upv=1&sxsrf=ACQVn08iH3zunvzS0ivnfzwRq5gWnfTVOw%3A1708417625357&ei=WWLUZc2iFdCu4-EPnuCC8AQ&ved=0ahUKEwiNs7_Nv7mEAxVQ1zgGHR6wAE4Q4dUDCBA&uact=5&oq=patel+celebration+besa&gs_lp=Egxnd3Mtd2l6LXNlcnAiFnBhdGVsIGNlbGVicmF0aW9uIGJlc2EyChAjGIAEGIoFGCcyCxAuGK8BGMcBGIAEMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgMyCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGAzIaEC4YrwEYxwEYgAQYlwUY3AQY3gQY4ATYAQFIrDVQoglYxjJwBngAkAEAmAGwAaAB_wmqAQMwLji4AQPIAQD4AQHCAg0QIxiABBiKBRiwAxgnwgITEC4YQxivARjHARiABBiKBRiwA8ICCBAAGIAEGLADwgIJEAAYBxgeGLADwgIFEAAYgATCAhAQLhhDGK8BGMcBGIAEGIoFwgIIEAAYgAQYywHCAggQABgWGB4YD8ICBxAjGLACGCfCAg0QLhgNGK8BGMcBGIAEwgIIEAAYCBgeGA3CAhwQLhgNGK8BGMcBGIAEGJcFGNwEGN4EGOAE2AEBiAYBkAYKugYGCAEQARgU&sclient=gws-wiz-serp">
                  <IoLogoGoogleplus
                    style={{ color: "black", height: "25px", width: "25px" }}
                  />
                </NavLink>
              </li>
            </ul>
          </span>
        </div>
        <div className="footerLogo">
          <img src={logo} alt="logo" />
        </div>
        <div className="footerDes">
          <span>
            <SlPhone className="footicon" />
          </span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <div className="footT">Call</div>
            <div className="footdes">+91 8484841444</div>
          </span>
        </div>
      </div>
      <div className="footerbase">
        <h6> &copy; 2023 All Rights Reserved To Patel Celebration</h6>
      </div>
    </div>
  );
}

export default Footer;
