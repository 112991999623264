
import React from 'react';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import About from './Pages/about/About'
import Inquiry from './Pages/inquiry/Inquiry';


import Home from './Pages/Home/Home';
import Gallary from './Pages/Gallary/Gallary';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route path="/" element={<Home/>}/>
       <Route path="Gallary" element={<Gallary/>} />
       <Route path="About" element={<About/>} />
       <Route path="Contact" element={<Inquiry/>} />
       
    </Routes>
    <Footer/>
  </BrowserRouter>
     
    </div>
  );
}

export default App;
