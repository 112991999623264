import React from "react";
import "./about.css";

function About() {
  return (
    <div>
      <div className="text">
        <p> About Us</p>
      </div>
      <div className="hall">
        <div className="img1">
          <img src="./images/freecompress-w16.jpeg" alt="" />
        </div>
        <div className="textone">
          <h1 style={{ color: "maroon" }}>OUR HISTORY</h1>
          <h3 style={{ textAlign: "center" }}>The dreamy journey</h3>
          <p>
            Patel Celebration began with a dream of providing a luxurious venue
            to share the memorable events of a person’s life. Since its
            inception, Patel Celebration has witnessed over 100 events taking
            place, including successful marriages, dream gatherings, and loads
            of fun-filled experiences. The initial dream of Patel Celebration
            grew to take the form of our elite venue for celebrating special
            events in someone’s life with grandeur and style.
          </p>
        </div>
      </div>
      <div className="hall2">
        <div className="texttwo">
          <h1 style={{ color: "maroon" }}>OUR SPECIALTY</h1>
          <h3 style={{ textAlign: "center" }}>Your dream our specialization</h3>
          <p>
            "We specialize in A to Z of event chores. Patel Celebration is an
            open lawn, thus the possibilities expand to organize literally
            everything in any way you can think of. Everything from the planning
            of the event to successful execution, procuring the venue, and
            arranging the catering – Patel Celebration will take care of
            everything in a Shahi way for you."
          </p>
        </div>
        <div className="img2">
          <img src="./images/freecompress-w15.jpeg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default About;
