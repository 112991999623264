import React from "react";
import couple from "../../Assets/Images/lawnstage 1.jpg";
import "./Home.css";
import Events from "../../Components/Events/Events";
import ServiceProvide from "../../Components/ServiceProvide/ServiceProvide";
import Couple from "../../Components/Couple/Couple";
import whatsapp from "../../Assets/Images/whatsappicon.png";
import call from "../../Assets/Images/phone-call.png";
import entrance from "../../Assets/Images/lawnentance1.jpg";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <div className="homeWrapper">
      <div className="home1">
        <img src={entrance} alt="img" />
      </div>
      <ServiceProvide />

      <div className="about1">
        <div className="aboutWrapper">
          <div className="div1">
            <img src={couple} alt="entrance" />
          </div>
          <div className="div2">
            <h1>About Us</h1>
            <p>
            "We welcome you to our new grand celebration lawn ‘Patel Celebration Lawn’ at Besa Square, Nagpur. Spanning an impressive 45,000 sqft, the lawn offers ample space for hosting various events and gatherings. With a dedicated parking area of 30,000 sqft, including 10,000 sqft for two-wheelers and 20,000 sqft for four-wheelers, accommodating over 1000 guests is made convenient. The lawn also features a spacious kitchen area of almost 1000 sqft, equipped with power backup. Visit the lawn once, and you will definitely love it."
            </p>
            <NavLink to={"/about"} href="About">
              <button style={{ fontFamily: "cursive" }}>KNOW MORE</button>
            </NavLink>
          </div>
        </div>
      </div>
      <Events />

      <Couple />
      <div className="socialIcon1">
        <a class="whats-app1" href=" https://wa.me/+919881155161">
          <img
            className="icimg1"
            style={{
              height: "60px",
              width: "50px",
            }}
            src={whatsapp}
            alt="whatsapp"
          />
        </a>
        <a class="call1" href="tel:+919881155161">
          <img
            className="icimg1"
            style={{
              height: "60px",
              width: "50px",
            }}
            src={call}
            alt="call"
          />
        </a>
      </div>
    </div>
  );
}

export default Home;
