import "./inquiry.css";
import whatsapp from "../../Assets/Images/whatsappicon.png";
import call from "../../Assets/Images/phone-call.png";

function Inquiry() {
  return (
    <div>
      <div className="pageName">
        <div className="pageText">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className="quiryContainer">
        <h2>Reach Out To Us</h2>

        <div className="twocontainer">
          <div className="icons">
            <div class="card">
              <div className="what">
                <a href="https://wa.me/+918484841444">
                  <img src={whatsapp} alt="whatsapp" className="whatButton" />
                </a>
              </div>
              <p>Chat With Us</p>
            </div>
            <div class="card">
              <div className="calll">
                <a href="tel:918484841444">
                  <img src={call} alt="call" />
                </a>
              </div>
              <p>Contact Us</p>
            </div>
          </div>

          <div className="location">
            <iframe
              title="patel"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14890.601673643381!2d79.0957317!3d21.0866201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4bf2f4ca26ea5%3A0xdd7998ef911cd576!2sPatel%20celebration%20lawn!5e0!3m2!1sen!2sin!4v1702980020167!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inquiry;
