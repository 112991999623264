import React from "react";
import "./ServiceProvide.css";
import image from "../../Assets/Images/weddingCouple.png";
import { ImMusic } from "react-icons/im";
import { FaCar, FaBed } from "react-icons/fa6";
import { FaWifi } from "react-icons/fa";

function ServiceProvide() {
  return (
    <div className="service1Wrapper">
    <div className="divWrapper">
      <div className="imgdiv">
        <img
          className="serimg"
         
          src={image}
          alt="wed"
        />
      </div>
      <div className="desWrapper">
        <div className="des1">
          <div className="serHead">
            <h1>We Provide You Best Services</h1>
          </div>
          <div>
            <h3>PROPERTY AMENITIES</h3>
          </div>
          <div>
            <p>
              We capture the essence of weddings. From pre-wedding festivities
              of haldi and mehndi to the big day and memorable
              receptions, trust Patel Lawn to{" "}
              simplify your planning and make your wedding exactly the way you
              dreamed.
            </p>
          </div>
        </div>
        <div className="des2">
          <div className="serWrapper">
            <div className="ser">
              <div>
                <FaCar className="icon"  />
              </div>
              <div className="iname">Spacious Parking</div>
            </div>
            <div className="ser">
              <div>
                <ImMusic className="icon" />
              </div>
              <div className="iname" >Music & Lighting</div>
            </div>
          </div>
          <div className="serWrapper">
            <div className="ser">
              <div>
                <FaWifi  className="icon"  />
              </div>
              <div className="iname">High Speed Wi-Fi</div>
            </div>
            <div className="ser">
              <div>
                <FaBed className="icon"  />
              </div>
              <div className="iname" >Spacious Rooms</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ServiceProvide;
