import React from "react";
import "./Gallary.css";
import NewCouple from "../../Components/Newcouple/NewCouple";
import whatsapp from "../../Assets/Images/whatsappicon.png";
import call from "../../Assets/Images/phone-call.png";
import Couple from "../../Components/Couple/Couple";
function Gallary() {
  return (
    <div>
      <div className="imgWrapper">
        <h1>Gallery</h1>
      </div>
      <div className="imgWrapper2">
        <div className="textWrapper">
          <div className="gallaryt">SOME SPECIAL MOMENTS</div>
        </div>
        <div
          className="couple_img
        "
        >
          <Couple />
          <NewCouple />
        </div>
      </div>
      <div className="socialIcon1">
        <a class="whats-app1" href=" https://wa.me/+919881155161">
          <img
            className="icimg1"
            style={{
              height: "55px",
              width: "50px",
            }}
            src={whatsapp}
            alt="whatsapp"
          />
        </a>
        <a class="call1" href="tel:+919881155161">
          <img
            className="icimg1"
            style={{
              height: "55px",
              width: "50px",
            }}
            src={call}
            alt="call"
          />
        </a>
      </div>
    </div>
  );
}

export default Gallary;
