import React from "react";
import "./Events.css";
import wedding from "../../Assets/svg/weddingsin 1.svg";
import Reception from "../../Assets/svg/reception.svg";
import engagement from "../../Assets/svg/rings 1.svg";
import tilak from "../../Assets/Images/tilak.jpg";
import party from "../../Assets/svg/party 1.svg";
import event from "../../Assets/svg/event.svg";

function Events() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <h1 className="eventhead">Our Services</h1>
      <div className="EventWrapper">
        <div className="eventContent">
          <img src={wedding} alt="wedding" />
          <h2>Wedding</h2>
        </div>
        <div className="eventContent">
          <img src={Reception} alt="reception" />
          <h2>Reception</h2>
        </div>
        <div className="eventContent">
          <img src={engagement} alt="engagement" />
          <h2>Engagement</h2>
        </div>
        <div className="eventContent">
          <img src={tilak} alt="tilak" />
          <h2>Tilak</h2>
        </div>
        <div className="eventContent">
          <img src={party} alt="party" />
          <h2>Party</h2>
        </div>
        <div className="eventContent">
          <img src={event} alt="event" />
          <h2>Events</h2>
        </div>
      </div>
    </div>
  );
}

export default Events;
