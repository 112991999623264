import React from "react";
import "./Header.css";
import { SlLocationPin, SlPhone } from "react-icons/sl";
import whatsapp from "../../Assets/Images/whatsappicon.png";
import call from "../../Assets/Images/phone-call.png";
import { useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import logo from "../../Assets/svg/logo.svg";
import { IoLogoGoogleplus } from "react-icons/io";
import { NavLink } from "react-router-dom";

function Header() {
  const [open, setOpen] = useState(false);
  const [click, setClick] = useState(false);

  return (
    <div
      className="headerWrapper"
      style={{ position: "relative", top: "0", width: "100%" }}
    >
      <div className="header1">
        <h6> Welcome To Patel Celebration</h6>
        <ul className="socialicon">
          <li>
            <NavLink to="https://www.facebook.com/profile.php?id=61556256014733">
              <FaFacebookF
                style={{ color: "white", height: "15px", width: "15px" }}
              />{" "}
            </NavLink>
          </li>

          <li>
            <NavLink to="https://www.google.com/search?q=patel+celebration+besa&sca_esv=bb2713ba757904f7&sca_upv=1&sxsrf=ACQVn08iH3zunvzS0ivnfzwRq5gWnfTVOw%3A1708417625357&ei=WWLUZc2iFdCu4-EPnuCC8AQ&ved=0ahUKEwiNs7_Nv7mEAxVQ1zgGHR6wAE4Q4dUDCBA&uact=5&oq=patel+celebration+besa&gs_lp=Egxnd3Mtd2l6LXNlcnAiFnBhdGVsIGNlbGVicmF0aW9uIGJlc2EyChAjGIAEGIoFGCcyCxAuGK8BGMcBGIAEMgYQABgWGB4yCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGAzILEAAYgAQYigUYhgMyCxAAGIAEGIoFGIYDMgsQABiABBiKBRiGAzIaEC4YrwEYxwEYgAQYlwUY3AQY3gQY4ATYAQFIrDVQoglYxjJwBngAkAEAmAGwAaAB_wmqAQMwLji4AQPIAQD4AQHCAg0QIxiABBiKBRiwAxgnwgITEC4YQxivARjHARiABBiKBRiwA8ICCBAAGIAEGLADwgIJEAAYBxgeGLADwgIFEAAYgATCAhAQLhhDGK8BGMcBGIAEGIoFwgIIEAAYgAQYywHCAggQABgWGB4YD8ICBxAjGLACGCfCAg0QLhgNGK8BGMcBGIAEwgIIEAAYCBgeGA3CAhwQLhgNGK8BGMcBGIAEGJcFGNwEGN4EGOAE2AEBiAYBkAYKugYGCAEQARgU&sclient=gws-wiz-serp">
              <IoLogoGoogleplus
                style={{ color: "white", height: "20px", width: "20px" }}
              />
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="header2">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span>
            <SlPhone
              style={{
                height: "40px",
                width: "40px",
                color: "red",
                paddingRight: "20px",
              }}
            />
          </span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ fontSize: "20px", fontFamily: "cursive", color: "red" }}
            >
              call
            </div>
            <div style={{ fontSize: "15px", fontWeight: "bold" }}>
              +91 8484841444
            </div>
          </span>
        </div>
        <div className="logo">
          <img
            style={{ height: "150px", width: "250px" }}
            src={logo}
            alt="logo"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span>
            <SlLocationPin
              style={{
                height: "40px",
                width: "40px",
                color: "red",
                paddingRight: "20px",
              }}
            />
          </span>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="Callspan"
              style={{ fontSize: "20px", fontFamily: "cursive", color: "red" }}
            >
              Location
            </div>
            <div
              className="Callspan"
              style={{ fontSize: "15px", fontWeight: "bold" }}
            >
              PATEL NAGAR, BESA SQUARE,<br></br> NAGPUR-440037
            </div>
          </span>
        </div>
      </div>

      <div className="header3">
        <div className="Nav">
          <NavLink
            to={"/"}
            style={{ textDecoration: "none", color: "black" }}
            href="/"
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            to={"/about"}
            style={{ textDecoration: "none", color: "black" }}
            href="About"
          >
            <li>About Us</li>
          </NavLink>

          <NavLink
            to={"/gallary"}
            style={{ textDecoration: "none", color: "black" }}
            href="Gallary"
          >
            <li>Gallery</li>
          </NavLink>
          <NavLink
            to={"/contact"}
            style={{ textDecoration: "none", color: "black" }}
            href="Contact"
          >
            <li>Contact Us</li>
          </NavLink>
        </div>

        <div>
          <button class="openbtn" onClick={() => setClick(!click)}>
            ☰
          </button>
        </div>
        {click && (
          <div className="sidebar">
            <span className="closebtn">
              <div onClick={() => setClick(!click)}> ×</div>
            </span>
            <NavLink
              to={"/"}
              style={{ textDecoration: "none", color: "black" }}
              href="/"
            >
              <li>Home</li>
            </NavLink>
            <NavLink
              to={"/gallary"}
              style={{ textDecoration: "none", color: "black" }}
              href="Gallary"
            >
              <li>Gallery</li>
            </NavLink>
            <NavLink
              to={"/about"}
              style={{ textDecoration: "none", color: "black" }}
              href="About"
            >
              <li>About</li>
            </NavLink>
            <NavLink
              to={"/contact"}
              style={{ textDecoration: "none", color: "black" }}
              href="Contact"
            >
              <li>Contact Us</li>
            </NavLink>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img className="logo2" src={logo} alt="logo" />
        </div>

        <div>
          <button className="bookbtn" onClick={() => setOpen(!open)}>
            Booking Enquiry
          </button>
        </div>

        {open ? (
          <div
            className="popdiv"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "right",
              marginLeft: "20px",
              width: "180px",
              padding: "10px",
              backgroundColor: "white",
              position: "absolute",
              right: "5%",
              top: "100%",
              borderRadius: "10px",
            }}
          >
            <div style={{ borderBottom: "1px solid grey" }}>
              <a href=" https://wa.me/+918484841444">
                <img
                  style={{
                    height: "65px",
                    width: "65px",
                    paddingBottom: "20px",
                    paddingLeft: "30px",
                    paddingTop: "10px",
                  }}
                  src={whatsapp}
                  alt="whatsapp"
                />
              </a>
              <p
                style={{
                  fontSize: "20px",
                  paddingLeft: "5px",
                  paddingTop: "15px",
                }}
              >
                Chat
              </p>
            </div>
            <div style={{ paddingTop: "10px" }}>
              <a href="tel:918484841444">
                <img
                  style={{
                    height: "45px",
                    width: "60px",
                    paddingLeft: "30px",
                    paddingTop: "10px",
                  }}
                  src={call}
                  alt="call"
                />
              </a>
              <p
                style={{
                  fontSize: "20px",
                  paddingLeft: "5px",
                  textAlign: "center",
                  paddingTop: "12px",
                }}
              >
                Call
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Header;
