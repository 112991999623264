import React from "react";
import couple1 from "../../Assets/Images/couple1_11zon.jpg";
import couple2 from "../../Assets/Images/couple2_11zon.jpg";
import couple3 from "../../Assets/Images/couple3.png";
import couple4 from "../../Assets/Images/couple4.png";
import couple5 from "../../Assets/Images/couple5.png";
import "./Couple.css";

function Couple() {
  return (
    <div className="coupleWrapper">
      <div className="coupleHead">
        <h1>Happy Couples</h1>
      </div>
      <div className="couple1">
        <img src={couple1} alt="couple" />
        <img src={couple2} alt="couple" />
        <img src={couple3} alt="couple" />
        <img src={couple4} alt="couple" />
        <img src={couple5} alt="couple" />
        <img src={couple1} alt="couple" />{" "}
      </div>
    </div>
  );
}

export default Couple;
