import React from "react";
import n1 from "../../Assets/Images/jpeg-optimizer_DSC07369 (1).JPG";
import n2 from "../../Assets/Images/jpeg-optimizer_DSC07397.JPG";
import n3 from "../../Assets/Images/jpeg-optimizer_DSC07421.JPG";
import n4 from "../../Assets/Images/freecompress-Screenshot_20240219_133308.jpeg";
import n5 from "../../Assets/Images/jpeg-optimizer_DSC08035.JPG";
import n6 from "../../Assets/Images/_S7A0630_11zon.jpg";
import n7 from "../../Assets/Images/_S7A0699_11zon.jpg";
import n8 from "../../Assets/Images/_S7A0612_11zon.jpg";
import "../Couple/Couple.css";
function NewCouple() {
  return (
    <>
      <div className="coupleWrapper">
        <div className="couple1">
          <img src={n1} alt="" style={{ borderRadius: "57px" }} />
          <img src={n2} alt="" style={{ borderRadius: "57px" }} />
          <img src={n3} alt="" style={{ borderRadius: "57px" }} />
          <img src={n4} alt="" style={{ borderRadius: "57px" }} />
          <img src={n5} alt="" style={{ borderRadius: "57px" }} />
          <img src={n1} alt="" style={{ borderRadius: "57px" }} />
          <img src={n6} alt="" style={{ borderRadius: "57px" }} />
          <img src={n7} alt="" style={{ borderRadius: "57px" }} />
          <img src={n8} alt="" style={{ borderRadius: "57px" }} />
        </div>
        <div className="video">
          <iframe
            title="youtube video"
            src="https://www.youtube.com/embed/Ooad_ibd7dw?si=d8UBhCdrPmAJXl2l"
            frameBorder="0"
            // width={853}
            // height={480}
            // style={{ height: "350px", width: "80%" }}
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default NewCouple;
